import React from "react"
import styled from "styled-components"

// importing components
import LaunchComponent from "../launch-site/topSection/launchComponent"
import Video from "../launch-site/topSection/videoComponent"

const topSection = () => {
  return (
    <Container>
      <LaunchComponent />
      <Video />
    </Container>
  )
}

export default topSection

const Container = styled.div`
  height: auto;
  width: 100vw;

  // resetting
  margin: 0 auto;
  padding: 0;

  // scroll snap

  // display-options
  display: flex;
  flex-direction: column;
  align-items: center;

  // media query
  @media screen and (min-width: 768px) {
    // size
    height: 100vh;

    // display-options
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`
