import React from "react"
import styled from "styled-components"

import ReactPlayer from "react-player/lazy"

// video: "../../../../../images/Nightlife/stock-video.mp4"

const videoComponent = () => {
  return (
    <Container>
      <StyledReactPlayer
        url="https://queue-video.s3.eu-central-1.amazonaws.com/launch-video.mp4"
        playing
        width="100%"
        height="100%"
        muted={true}
        loop={true}
      />
    </Container>
  )
}

export default videoComponent

const Container = styled.div`
  // size
  height: 100vh;
  width: 100vw;

  // resetting
  margin: 0;
  padding: 5% 15%;

  // resetting video-player margin
  * {
    margin: 0;
    padding: 0;
  }

  // media querys
  @media screen and (min-width: 768px) {
    // size
    height: 100%;
    width: 50%;

    // padding
    padding: 5% 0% 5% 0;
  }
`

const StyledReactPlayer = styled(ReactPlayer)`
  object-fit: contain;
`
