import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// importing images
import instagram from "../../../../images/Nightlife/instagram-logo.png"

const BottomSection = () => {
  return (
    <Container>
      <Header>Opening next in:</Header>
      <OpeningNext>
        <Grid>
          <ListItem>Roskilde</ListItem>
          <ListItem>Aarhus</ListItem>
          <ListItem>Malmö</ListItem>
        </Grid>
      </OpeningNext>
      <StyledLink to="/home">
        <BeReady>Be Ready</BeReady>
      </StyledLink>
      <Footer>
        <Instagram />
      </Footer>
    </Container>
  )
}

export default BottomSection

const Container = styled.div`
  // size
  height: 100vh;
  width: 100vw;

  // resetting
  margin: 0;
  padding: 0;

  // scroll snap

  // display-options
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  // media query
  @media screen and (min-width: 768px) {
    // resetting
    margin-left: 10%;
    padding: 0;

    // size
    min-height: 30vh;
    height: 70vh;

    // display-options
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const OpeningNext = styled.div`
  // size
  height: auto;
  width: 100%;

  // resetting
  margin: 0;
  padding: 0;

  // scroll snap

  // display-options
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  // media query
  @media screen and (min-width: 768px) {
    // display-options
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const Grid = styled.div`
  // size
  height: 100%;
  width: 100%;

  // resetting
  margin: 0;
  padding: 0;

  // display-options
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // media query
  @media screen and (min-width: 768px) {
    // size
    height: 100%;

    // display-options
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }
`

const Header = styled.h1`
  // resetting
  margin-top: 10%;
  padding: 0;

  // typography
  font-size: 3rem;
  font-weight: bold;

  // alignment
  text-align: center;

  // color
  color: #fff;

  // media query
  @media screen and (min-width: 768px) {
    // alignment
    text-align: left;
    align-self: flex-start;

    // margin
    margin-top: 2vh;
  }

  // media query for larger headings
  @media screen and (min-width: 1400px) {
    // size
    font-size: 3.5rem;
  }
`

const ListItem = styled.p`
  // resetting
  margin: 30px 0;
  padding: 0;

  // typography
  font-size: 2.5rem;
  font-weight: normal;

  // alignment
  text-align: center;

  // color
  color: #f2f2f2;

  // media query
  @media screen and (min-width: 768px) {
    // margin
    margin: 0;

    // alignment
    text-align: left;

    width: 100%;
  }

  // media query for larger headings
  @media screen and (min-width: 1400px) {
    // size
    font-size: 3.5rem;
  }
`

const Footer = styled.div`
  // size
  height: 20%;
  width: 100%;

  // resetting
  margin: 0;
  padding: 0;

  // scroll snap

  // display-options
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-end;

  // media query
  @media screen and (min-width: 768px) {
    // display
    justify-content: flex-start;
  }
`

const BeReady = styled.div`
  // resetting
  margin: 0 0 0 0;
  padding: 0;

  // size
  height: 60px;
  width: 100%;
  border-radius: 50px;

  // display-options
  display: flex;
  align-items: center;
  justify-content: center;

  // typography
  font-size: 1.5rem;
  font-weight: bold;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);
  -moz-box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);
  box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);

  @media screen and (min-width: 768px) {
    // margin
  }

  @media screen and (min-width: 1600px) {
    // size
    height: 70px;
  }

  @media screen and (min-width: 1920px) {
    // size
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  // resetting
  margin: 0;
  padding: 0;

  // size
  height: 60px;
  width: 50%;
  border-radius: 50px;

  // typography
  font-size: 1.5rem;

  // color
  color: #333;

  // display-options
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 550px) {
    // size
    width: 40%;
  }

  @media screen and (min-width: 768px) {
    // size
    width: 24%;
  }

  @media screen and (min-width: 1600px) {
    // size
    width: 20%;
  }

  @media screen and (min-width: 1920px) {
    // size
    width: 16%;
  }

  @media screen and (min-width: 2800px) {
    // size
    width: 12%;
  }
`

const Instagram = styled.img`
  // size
  height: 80px;
  width: 80px;

  // content
  content: url(${instagram});

  // resetting
  margin-bottom: 20px;
  padding: 0;
`
