import React from "react"
import styled from "styled-components"

// Styles
import GlobalStyles from "./home/components/global"

// Components
import TopSection from "./home/components/launch-site/topSection"
import BottomSection from "./home/components/launch-site/bottomSection"

const Launch = () => {
  return (
    <Container>
      <TopSection />
      <BottomSection />
      <GlobalStyles />
    </Container>
  )
}

export default Launch

const Container = styled.div`
  // resetting
  margin: 0 auto;
  padding: 0;
`
